.divTable {
  &-max-height {
    height: 100%;
  }

  .k-grid {
    border-top: none;
  }

  .searchDiv {
    background-color: #f6f6f6;
    margin-bottom: 0px;
    margin-right: 1px;
    margin-left: 1px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: none;

    .searchField {
      width: 100%;

      &-text-inside {
        .MuiFilledInput-underline:after {
          transform: scaleX(1);
        }
      }

      label {
        font-size: 14px;
        color: rgb(101, 101, 101);
      }

      .MuiFilledInput-underline:before {
        border-bottom-color: rgba(0, 0, 0, 0.08);
      }

      .MuiFilledInput-underline:after {
        border-bottom-color: #ff6358;
        margin: 0 5px;
      }

      .MuiFilledInput-root {
        background-color: transparent;

        input {
          background-color: white;
          margin: 5px 5px 5px 5px;
          height: 0em;
          color: rgb(101, 101, 101);
          font-size: 14px;
        }
      }
    }
  }
}
.tableTitle {
  @media (-webkit-device-pixel-ratio: 1){
    font-size: 1.35rem;
  }
  @media (-webkit-device-pixel-ratio: 1.25){
    font-size: 1.35*0.85rem;
  }
  @media (-webkit-device-pixel-ratio: 1.5){
    font-size: 1.35*0.6rem;
  }
  @media (-webkit-device-pixel-ratio: 1.75){
    font-size: 1.35*0.45rem;
  }
  @media (-webkit-device-pixel-ratio: 2){
    font-size: 1.35*0.3rem;
  }
  color: white;
}
