.bottom-bar {
  display: flex;
  flex-direction: row;
  background-color: #f1df01;
  height: 30px;
  min-height: 30px !important;
  color: rgb(0,52,120);
 

  .left {
    @media (-webkit-device-pixel-ratio: 1){
      font-size: 0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      font-size: 0.85*0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      font-size: 0.85*0.6rem;
    }
    @media (-webkit-device-pixel-ratio: 1.75){
      font-size: 0.85*0.45rem;
    }
    @media (-webkit-device-pixel-ratio: 2){
      font-size: 0.85*0.3rem;
    }
    margin-bottom: unset;
    flex: 1;
    text-align: left;
  }
  .center {
    @media (-webkit-device-pixel-ratio: 1){
      font-size: 0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      font-size: 0.85*0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      font-size: 0.85*0.6rem;
    }
    @media (-webkit-device-pixel-ratio: 1.75){
      font-size: 0.85*0.45rem;
    }
    @media (-webkit-device-pixel-ratio: 2){
      font-size: 0.85*0.3rem;
    }
    margin-bottom: unset;
    flex: 2;
    text-align: center;
  }
  .right {
    @media (-webkit-device-pixel-ratio: 1){
      font-size: 0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      font-size: 0.85*0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      font-size: 0.85*0.6rem;
    }
    @media (-webkit-device-pixel-ratio: 1.75){
      font-size: 0.85*0.45rem;
    }
    @media (-webkit-device-pixel-ratio: 2){
      font-size: 0.85*0.3rem;
    }
    margin-bottom: unset;
    flex: 1;
    text-align: right;
  }
}
