.tableTemplate {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.tableButtons {
		margin-top: 20px;
		margin-bottom: 10px;
		height: calc(100% - 36px);
		width: 100%;
		display: inline-block;
		position: relative;

		.filler {
			width: 15%;
			display: inline-block;
		}

		.table {
			height: 100%;
			width: 69%;
			display: inline-block;
			margin-right: 15px;

			hr {
				width: 100%;
				margin-bottom: 2rem;
			}
		}

		.buttons {
			margin-left: 15px;
			height: 170px;
			width: 14%;
			display: inline-block;
			position: absolute;
			bottom: 0;

			.emptyBlock {
				width: 100%;
				height: 36px;
				background-color: #e0e0e0;
				margin-top: 20px;
			}
			button,
			a[role="button"] {
				margin-top: 20px;
				height: 36px;
				width: 100%;
				@media (-webkit-device-pixel-ratio: 1){
				  font-size: 0.85rem important;
				}
				@media (-webkit-device-pixel-ratio: 1.25){
				  font-size: 0.85*0.85rem important;
				}
				@media (-webkit-device-pixel-ratio: 1.5){
				  font-size: 0.85*0.6rem important;
				}
				@media (-webkit-device-pixel-ratio: 1.75){
				  font-size: 0.85*0.45rem important;
				}
				@media (-webkit-device-pixel-ratio: 2){
				  font-size: 0.85*0.3rem important;
				}
			}

			button.Mui-disabled,
			a[role="button"].Mui-disabled {
				background-color: rgba(0, 0, 0, 0.44);
				color: white;
			}
		}
		.buttonsRight {
			width: 14%;
			position: absolute;
			height: 170px;
			bottom: 0;
			margin-right: 15px;
			.emptyBlock {
				width: 100%;
				height: 36px;
				background-color: #e0e0e0;
				margin-top: 20px;
			}
			button,
			a[role="button"] {
				margin-top: 20px;
				height: 36px;
				width: 100%;
				@media (-webkit-device-pixel-ratio: 1){
					font-size: 0.85rem important;
				  }
				  @media (-webkit-device-pixel-ratio: 1.25){
					font-size: 0.85*0.85rem important;
				  }
				  @media (-webkit-device-pixel-ratio: 1.5){
					font-size: 0.85*0.6rem important;
				  }
				  @media (-webkit-device-pixel-ratio: 1.75){
					font-size: 0.85*0.45rem important;
				  }
				  @media (-webkit-device-pixel-ratio: 2){
					font-size: 0.85*0.3rem important;
				  }
			}

			button.Mui-disabled,
			a[role="button"].Mui-disabled {
				background-color: rgba(0, 0, 0, 0.44);
				color: white;
			}
		}
	}
}
