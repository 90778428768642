.home {
  display: flex;
  flex-direction: column;
  width: 100%;

  .welcome {
    @media (-webkit-device-pixel-ratio: 1){
      font-size: 3rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      font-size: 3*0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      font-size: 3*0.6rem;
    }
    @media (-webkit-device-pixel-ratio: 1.75){
      font-size: 3*0.45rem;
    }
    @media (-webkit-device-pixel-ratio: 2){
      font-size: 3*0.3rem;
    }
    color: white;
    //font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .image {
    width: 100%;
    height: 20rem;
    background-image: url("/imgs/ECRAN 1_Banner technicien maintenance.jpg");
    background-size: cover;
    color: #000;
    text-shadow: 1px 1px 0 #fff;
    background-position: center;
    flex: 1;
  }

  .bottom-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    height: 99%;
    flex: 1;

    .left-message {
      color: white;
      @media (-webkit-device-pixel-ratio: 1){
        font-size: 2.5rem;
      }
      @media (-webkit-device-pixel-ratio: 1.25){
        font-size:2.5*0.85rem;
      }
      @media (-webkit-device-pixel-ratio: 1.5){
        font-size: 2.5*0.6rem;
      }
      @media (-webkit-device-pixel-ratio: 1.75){
        font-size: 2.5*0.45rem
      }
      @media (-webkit-device-pixel-ratio: 2){
        font-size: 2.5*0.3rem;
      }
      // font-weight: bold;
      flex: 2;
      margin-right: 10px;
      align-self: center;
      margin-top: 2rem;
      height: 90%;
    }

    .right-message {
      color: white;
      flex: 2;
      margin-left: 10px;
      align-self: center;
      margin-top: 2rem;
      height: 90%;

      .availability {
        @media (-webkit-device-pixel-ratio: 1){
          font-size: 2.5rem;
        }
        @media (-webkit-device-pixel-ratio: 1.25){
          font-size:2.5*0.85rem;
        }
        @media (-webkit-device-pixel-ratio: 1.5){
          font-size: 2.5*0.6rem;
        }
        @media (-webkit-device-pixel-ratio: 1.75){
          font-size: 2.5*0.45rem
        }
        @media (-webkit-device-pixel-ratio: 2){
          font-size: 2.5*0.3rem;
        }
        // font-weight: lighter;
      }
      .activity {
        @media (-webkit-device-pixel-ratio: 1){
          font-size: 2.5rem;
        }
        @media (-webkit-device-pixel-ratio: 1.25){
          font-size:2.5*0.85rem;
        }
        @media (-webkit-device-pixel-ratio: 1.5){
          font-size: 2.5*0.6rem;
        }
        @media (-webkit-device-pixel-ratio: 1.75){
          font-size: 2.5*0.45rem
        }
        @media (-webkit-device-pixel-ratio: 2){
          font-size: 2.5*0.3rem;
        }
        // font-weight: bold;
      }
    }

    .buttons {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-self: flex-end;

      button {
        margin-top: 20px;
      }

      .emptyBlock {
        width: 100%;
        height: 36px;
        background-color: #e0e0e0;
        margin-top: 20px;
      }
    }
  }
}
