.tableTemplateOnly {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	.tableButtons {
		margin-top: 20px;
		margin-bottom: 10px;
		height: calc(100% - 36px);
		width: 100%;
		display: inline-block;
		position: relative;

		.filler {
			width: 15%;
			display: inline-block;
		}

		.table {
			height: 100%;
			width: 100%;
			display: inline-block;
			margin-right: 15px;

			hr {
				width: 100%;
				margin-bottom: 2rem;
			}
		}
	}
}
