.top-bar {
    display: flex;
    width: 100%;
    background-color: #f1df01;
    color: rgb(0,52,120);

    .logo {
      height: 64px;  
      flex: 1; 
      background-size: contain;
      color: #000; text-shadow: 1px 1px 0 #fff;
      background-repeat: no-repeat;
      background-position-x: left;
    }
    .title {
      flex: 2;
      text-align: center;
      width: 20rem;
      color: rgb(0,52,120);
      @media (-webkit-device-pixel-ratio: 1){
        font-size: 2rem;
      }
      @media (-webkit-device-pixel-ratio: 1.25){
        font-size:2*0.85rem;
      }
      @media (-webkit-device-pixel-ratio: 1.5){
        font-size: 2*0.6rem;
      }
      @media (-webkit-device-pixel-ratio: 1.75){
        font-size: 2*0.45rem
      }
      @media (-webkit-device-pixel-ratio: 2){
        font-size: 2*0.3rem;
      }
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }

    .banner {
      height: 64px;
      flex: 1;
      float: right;
      background-size: contain;
      color: #000; text-shadow: 1px 1px 0 #fff;
      background-repeat: no-repeat;
      background-position-x: right;
    }

    .HDepannage {
      background-image: url(/imgs/24HDepannage.jpg);
    }

    .Logo {
      background-image: url(/imgs/Logo.jpg);
    }
  }