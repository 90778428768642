@font-face {
  font-family: 'SlateProBook';
  src: url("/slate-pro-book.otf");
  src: url("/slate-pro-book.otf") format('embedded-opentype');
}

body {
  margin: 0;
  font-family: 'SlateProBook', -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.appContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

#root > div {
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: darkblue;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;

  .navigation {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .content {
    overflow: auto;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    height: 100%;

    .rooter-content {
      height: calc(100% - 12px);
      margin-top: 12px;
      display: flex;
      justify-content: center;
    }

    .MuiButtonBase-root.MuiTableSortLabel-root {
      width: 100%;
    }
  }
}

.fondVehicule {
  background-image: url("/imgs/ECRAN 1_fond vehicules ELCO _1920x710.jpg");
}

.fondVehicule2 {
  background-image: url("/imgs/ECRAN 9_fond vehicules ELCO _1920x711.jpg");
}

.fond2Techniciens {
  background-image: url("/imgs/ECRAN 2_Fond 2 techniciens.jpg");
}

.fondTechniciens {
  background-image: url("/imgs/ECRAN 4_fond technicien_1920x710.jpg");
}

.fondToitVentilation {
  background-image: url("/imgs/ECRAN 3_Fond toit ventilation.jpg");
}

.fondBatiment {
  background-image: url("/imgs/ECRAN 5_fond batiment ELCO 1920x710.png");
}

.fondCollecteur1 {
  background-image: url("/imgs/ECRAN 6_fond collecteur1_1920x710.jpg");
}

.fondCollecteur2 {
  background-image: url("/imgs/ECRAN 8_fond collecteur2_1920x710.jpg");
}

.fondFroidToiture {
  background-image: url("/imgs/ECRAN 7_fond froid toiture _1920x710.jpg");
}
