.navigationHeader {
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  .navigation {
    display: flex;
    flex-direction: column;
    min-width: 33%;
    @media (-webkit-device-pixel-ratio: 1){
      font-size: 1.25rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      font-size:1.25*0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      font-size: 1.25*0.6rem;
    }
    @media (-webkit-device-pixel-ratio: 1.75){
      font-size: 1.25*0.45rem
    }
    @media (-webkit-device-pixel-ratio: 2){
      font-size: 1.25*0.3rem;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .label {
        width: 10ch;
      }

      .value {
        width: auto;
      }
    }
  }

  .login {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 33%;
    @media (-webkit-device-pixel-ratio: 1){
      font-size: 1.25rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      font-size:1.25*0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      font-size: 1.25*0.6rem;
    }
    @media (-webkit-device-pixel-ratio: 1.75){
      font-size: 1.25*0.45rem
    }
    @media (-webkit-device-pixel-ratio: 2){
      font-size: 1.25*0.3rem;
    }

    &-text,
    &-logout {
      color: white;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-logout {
      @media (-webkit-device-pixel-ratio: 1){
        font-size: 0.75rem;
      }
      @media (-webkit-device-pixel-ratio: 1.25){
        font-size:0.75*0.85rem;
      }
      @media (-webkit-device-pixel-ratio: 1.5){
        font-size: 0.75*0.6rem;
      }
      @media (-webkit-device-pixel-ratio: 1.75){
        font-size: 0.755*0.45rem
      }
      @media (-webkit-device-pixel-ratio: 2){
        font-size: 0.75*0.3rem;
      }
      svg {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .title {
    flex-direction: row;
    min-width: 33%;
    text-align: center;
    @media (-webkit-device-pixel-ratio: 1){
      font-size: 2.5rem;
    }
    @media (-webkit-device-pixel-ratio: 1.25){
      font-size:2.5*0.85rem;
    }
    @media (-webkit-device-pixel-ratio: 1.5){
      font-size: 2.5*0.6rem;
    }
    @media (-webkit-device-pixel-ratio: 1.75){
      font-size: 2.5*0.45rem
    }
    @media (-webkit-device-pixel-ratio: 2){
      font-size: 2.5*0.3rem;
    }
  }
}
